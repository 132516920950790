@import './Main_Media.css';

main svg {
    position: absolute;
    z-index: -1;
}

main svg:last-child {
    z-index: -1;
}

/* Header */

header {
    padding: 2% 2% 10px 2%;
}

nav {
    background-color: #ffffff;
    border-radius: 4px;
    /* box-shadow: 0 0 4px 0 lightgrey; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1%;
    position: relative;
}

.icon-title-box {
    display: inline-flex;
    align-items: center;
}

#Icon {
    width: 60px;
}

#Title {
    font-size: 2rem;
    margin-left: 10px;
}

.content-links-box {
    display: flex;
    align-items: center;
    padding: 1%;
}

.content-links-box a,
.content-links-menu a {
    font-size: 2rem;
    margin-right: 20px;
}

.content-links-menu {
    display: none;
}


/* Body */

/* Zero */

#Zero {
    padding: 10px 2%;
}

#Zero div {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 1%;
}

#Zero div div {
    background-color: #FF6347;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    margin-right: 10px;
}

/* First */

#First {
    display: flex;
    justify-content: space-between;
    padding: 10px 2%;
}

#First article {
    background-color: #ffffff;
    border-radius: 4px;
    /* box-shadow: 0 0 4px 0 lightgrey; */
    padding: 1%;
    width: 47%;
}

#First article h1 {
    font-size: 2rem;
    text-align: center;
}

#First article .photo-grid, .video-grid {
    display: flex;
    overflow-x: scroll;
    padding: 1%;
}

#First article div .photo-item {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    margin-right: 10px;
    height: 400px;
}

#First article div .video-item {
    margin-right: 10px;
    height: 400px;
    width: 300px;
}

#First article h2 {
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(4px);
    font-weight: normal;
    text-align: center;
    width: 300px;
}

/* Second */

#Second {
    padding: 10px 2%;
}

#Second article {
    background-color: #ffffff;
    border-radius: 4px;
    /* box-shadow: 0 0 4px 0 lightgrey; */
    display: flex;
    align-items: center;
    overflow-x: scroll;
    padding: 1%;
}

#Second article iframe {
    height: 200px;
    margin-right: 10px;
    width: 300px;
}

/* Footer */

footer {
    padding: 10px 2%;
}

#Footer {
    background-color: #ffffff;
    border-radius: 4px;
    /* box-shadow: 0 0 4px 0 lightgrey; */
    height: 64px;
}