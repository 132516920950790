@media only screen and (max-width: 1000px) {
    #First {
        flex-direction: column;
    }

    #First article:first-child {
        margin-bottom: 10px;
    }

    body #First article {
        width: auto;
    }
}

@media only screen and (max-width: 700px) {
    body #Title,
    body #First article h1 {
        font-size: 1.5rem;
    }

    body .content-links-box a,
    body .content-links-menu a {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 600px) {
    body #Icon {
        width: 40px;
    }

    body #First article {
        padding: 2%;
    }

    body #Second article {
        padding: 2%;
    }
}

@media only screen and (max-width: 560px) {
    body .content-links-box {
        background-color: white;
        display: none;
        justify-content: space-around;
        position: absolute;
        width: calc(100% - 20%);
    }

    body .content-links-box a {
        margin-right: 0;
    }

    body .content-links-menu {
        display: block;
        padding: 1%;
    }

    body .content-links-menu a {
        margin-right: 0;
    }

    body #First article div .photo-item, 
    body #First article div .video-item {
        height: 350px;
    }

    body #First article h2 {
        width: 250px;
    }
}

@media only screen and (max-width: 360px) {
    body #Title,
    body #First article h1 {
        font-size: 1.2rem;
    }

    body #First article div .photo-item, 
    body #First article div .video-item {
        height: calc(100dvh - 10dvh);
    }

    body #First article h2 {
        width: calc(100dvw - 10dvw);
    }

    body #Second article iframe {
        width: calc(100dvw - 10dv);
    }
}
